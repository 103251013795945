<template>
  <validation-observer
    ref="resourceSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="assignPermissionResourceModal"
      ref="assignPermissionResourceModalRef"
      body-class="position-static"
      centered
      size="xl"
      title="Phân quyền và đối tượng cho vai trò"
      :no-close-on-backdrop="true"
      @hide="onHide"
    >
      <b-row>
        <b-col cols="12">
          <b-form
            ref="formRef"
            :style="{height: trHeight}"
          >
            <b-row
              v-for="(item, index) in rolePermissionResources"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="4">
                <b-form-group label-for="permissionCode">
                  <template v-slot:label>
                    Quyền <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Quyền"
                    rules="required"
                  >
                    <v-select
                      id="permissionCode"
                      v-model="item.permissionCode"
                      label="name"
                      :options="permissions"
                      :reduce="option => option.code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label-for="resourceCode">
                  <template v-slot:label>
                    Đối tượng <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Đối tượng"
                    rules="required"
                  >
                    <v-select
                      id="resourceCode"
                      v-model="item.resourceCode"
                      label="name"
                      :options="resources"
                      :reduce="option => option.code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                lg="2"
                md="2"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>Xóa</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="repeatAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Thêm mới</span>
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="invalid || rolePermissionResources.length === 0"
            variant="primary"
            class="mr-1"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('assignPermissionResourceModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import { required } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AssignPermissionResource',
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BOverlay,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [heightTransition],
  props: {
    roleId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      rolePermissionResources: [{
        id: 1,
        permissionCode: null,
        resourceCode: null,
        orderNo: 1,
      }],
      nextId: 2,
      required,
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'role/permissionOptions',
      resources: 'role/resourcesOptions',
    }),
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions({
      createRolePermissionResource: 'role/createRolePermissionResource',
    }),
    repeatAgain() {
      this.rolePermissionResources.push({
        id: this.nextId += this.nextId,
        permissionCode: null,
        resourceCode: null,
        orderNo: 1,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.rolePermissionResources.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.assignPermissionResourceModalRef.scrollHeight)
      })
    },
    onHide() {
      this.rolePermissionResources = [{
        id: 1,
        permissionCode: null,
        resourceCode: null,
        orderNo: 1,
      }]
      this.nextId = 2
    },
    async onSave() {
      const valid = this.$refs
        .resourceSaveFormRef
        .validate()
      if (valid && this.rolePermissionResources.length > 0) {
        this.isLoading = true
        try {
          const response = await this.createRolePermissionResource({
            id: this.roleId,
            rolePermissionResources: this.rolePermissionResources,
          })
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
