<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row v-if="isSystemAdmin() || creatable">
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.roleSaveModal
                    variant="success"
                    @click="onCreateRole"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusIcon" /> Thêm vai trò
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row :class="{'mt-0': isSystemAdmin() || creatable}">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                    đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="roles"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>

                    <span v-else-if="props.column.field === 'action'">
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          v-if="isSystemAdmin() || updatable"
                          v-b-modal.roleSaveModal
                          @click="onEditRole(props.row)"
                        >
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">Sửa</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="isSystemAdmin() || rprReadable"
                          :to="{ name: 'authRolePermissionResource', params: { id: props.row.id }}"
                        >
                          <feather-icon icon="TargetIcon" />
                          <span class="align-middle ml-50">Phân quyền</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="isSystemAdmin() || deletable"
                          @click="onDeleteRole(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Xóa</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <RoleSave
      ref="roleSaveModalRef"
      :role="currentRole"
      @succeed="onSucceed"
    />
    <AssignPermission :id="selectedId" />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BCard, BCol, BContainer, BDropdown, BDropdownItem, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import RoleSave from '@/views/authorization/role/RoleSave.vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AssignPermission from '@/views/authorization/role-permission-resource/AssignPermissionResource.vue'
import { PermissionCode, ResourceCode } from '@/const/code'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  name: 'Role',
  directives: {
    Ripple,
  },
  components: {
    RoleSave,
    AssignPermission,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        code: '',
        name: '',
        status: '',
        sort: '',
      },
      currentRole: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã vai trò',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          width: '22%',
          thClass: 'text-center',
        },
        {
          label: 'Tên vai trò',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          width: '32%',
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: [
              { value: 1, text: 'Hoạt động' },
              { value: 0, text: 'Không hoạt động' },
            ],
          },
          width: '22%',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thứ tự',
          field: 'orderNo',
          sortable: true,
          width: '12%',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: '',
          field: 'action',
          width: '12%',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      selectedId: null,
    }
  },
  computed: {
    ...mapGetters({
      roles: 'role/roles',
      totalRows: 'role/totalRows',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.ROLE)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ROLE)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.ROLE)
    },
    rprReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.ROLE_PERMISSION_RESOURCE)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getRoles(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    isSystemAdmin,
    ...mapActions({
      getRoles: 'role/getRoles',
      deleteRole: 'role/deleteRole',
    }),
    onCreateRole() {
      this.currentRole = undefined
    },
    onEditRole(role) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = role
      this.currentRole = rest
    },
    onDeleteRole(role) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa vai trò <span class="text-danger">${role.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteRole(role.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getRoles(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getRolesFromStore()
    },
    async getRolesFromStore() {
      this.isLoading = true
      try {
        await this.getRoles(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage:1, itemsPerPage: param.currentPerPage })
      await this.getRolesFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'code') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getRolesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getRolesFromStore()
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getRolesFromStore()
    },
  },
}
</script>
